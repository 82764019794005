//find all .tab-button element and add click event listener
document.querySelectorAll('.tab-button').forEach(function (button) {
    button.addEventListener('click', function (e) {
        //prevent default action
        e.preventDefault();
        //get the target tab content id
        const target = button.dataset.target;
        //remove active class from all buttons
        document.querySelectorAll('.tab-button').forEach(function (button) {
            button.classList.remove('active');
        });
        //add active class to current button
        button.classList.add('active');
        //remove active class from all tab content
        document.querySelectorAll('.tab-pane').forEach(function (tabContent) {
            tabContent.classList.remove('active');
        });
        //add active class to target tab content
        document.getElementById('tab-'+target).classList.add('active');
    });
});
